<template>
  <splitted-layout class="w-full h-full flex px-2">
    <div class="border border-tertiary-200">Ciao</div>
  </splitted-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import SplittedLayout from '@/_templates/SplittedLayout.vue'
export default {
  components: { SplittedLayout },
  data: () => ({
    email: '',
    password: ''
  }),
  computed: {
    //...mapGetters("auth", ["currentUserData"]),
    ...mapGetters(
      { authError: 'auth/error' },
      { currentUSerData: 'auth/currentUserData' }
    )
  },
  async mounted() {
    setTimeout(async () => {
      await this.$store.dispatch('auth/signOut')
    }, 3000)
  }
}
</script>

<style lang="postcss" scoped></style>
