<template>
  <router-link to="/">
    <svg
      :width="size"
      viewBox="0 0 585.71 121.75"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          class="st0"
          :class="!dark ? '' : 'fill-current text-white'"
          d="M312.57,46.9c0-6.98,1.78-8.69,9.08-8.69h2.92c7.3,0,9.08,1.71,9.08,8.69v61.81c0,6.98-1.78,8.69-9.08,8.69
		h-2.92c-7.3,0-9.08-1.71-9.08-8.69V46.9z"
        />
        <path
          class="st0"
          :class="!dark ? '' : 'fill-current text-white'"
          d="M144.03,46.9c0-6.98,1.78-8.69,9.08-8.69h2.92c7.3,0,9.08,1.71,9.08,8.69v62.02c0,6.98-1.78,8.69-9.08,8.69
		h-2.92c-7.3,0-9.08-1.71-9.08-8.69V46.9z"
        />
        <ellipse class="st1" cx="154.57" cy="13.49" rx="13.66" ry="13.41" />
        <ellipse class="st0" cx="323.11" cy="13.49" rx="13.66" ry="13.41" />
        <radialGradient
          id="SVGID_1_"
          cx="54.4887"
          cy="110.351"
          r="93.1287"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" style="stop-color:#004C97" />
          <stop offset="0.6" style="stop-color:#6BC4E8" />
        </radialGradient>
        <path
          class="st2"
          d="M16.27,27.24c-7.34-1.5-14.93,3.94-16.09,11.17c-1.47,9.11,6.57,14.9,11.11,15.35
		c36.79,3.61,45.82,26.36,48.43,56.85c0,0,19.64,16.39,19.65-17.49C79.37,75.69,60.88,36.38,16.27,27.24z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="48.4343"
          y1="101.6662"
          x2="106.1925"
          y2="7.1063"
        >
          <stop offset="0" style="stop-color:#C4D600" />
          <stop offset="1" style="stop-color:#FFC845" />
        </linearGradient>
        <path
          class="st3"
          d="M114.59,1.1c6.18-0.61,13.59,3.99,14.65,11.94c0.96,7.12-4.43,13.9-12.32,14.96
		c-44.51,5.96-62.71,41.48-54.13,67.15c4.83,14.46,16.57,12.94,16.57-2.02c0,0,2.1,24.5-10.29,28.12
		C56.7,124.85,45.25,108.4,41.72,97.2C29.02,56.86,59.92,6.52,114.59,1.1z"
        />
        <path
          class="st0"
          :class="!dark ? '' : 'fill-current text-white'"
          d="M178.81,23.76c0-7.16,1.78-9.23,9.4-9.23h59.99c7.3,0,9.4,2.07,9.4,9.23v1.43c0,7.48-2.11,9.23-9.4,9.23
		h-19.05v73.95c0,7.16-2.11,9.23-9.4,9.23h-3.08c-7.62,0-9.4-2.07-9.4-9.23V34.43h-19.05c-7.62,0-9.4-1.75-9.4-9.23V23.76z"
        />
        <path
          class="st0"
          :class="!dark ? '' : 'fill-current text-white'"
          d="M278.25,108.69c0,7.16-1.78,8.91-9.08,8.91h-3.08c-7.3,0-9.08-1.75-9.08-8.91V65.09
		c0-18.94,11.67-28.97,33.72-28.97h1.78c6.32,0,8.27,2.7,8.27,10.19v1.75c0,7-1.62,8.12-8.27,7.64h-1.78
		c-9.89,0-12.48,3.18-12.48,10.98V108.69z"
        />
        <path
          class="st0"
          :class="!dark ? '' : 'fill-current text-white'"
          d="M371.51,108.69c0,7.16-1.78,8.91-9.08,8.91h-3.08c-7.3,0-9.08-1.75-9.08-8.91V68.27
		c0-21.03,10.22-32.15,35.91-32.15c25.69,0,35.91,11.12,35.91,32.15v40.43c0,7.16-1.78,8.91-9.08,8.91h-3.08
		c-7.3,0-9.08-1.75-9.08-8.91V68.27c0-8.91-2.19-12.57-14.67-12.57s-14.67,3.66-14.67,12.57V108.69z"
        />
        <path
          class="st0"
          :class="!dark ? '' : 'fill-current text-white'"
          d="M487.69,47.03c0-7.16,1.78-8.91,9.08-8.91h3.08c7.3,0,9.08,1.75,9.08,8.91v40.43
		c0,21.03-10.22,32.15-35.91,32.15s-35.91-11.12-35.91-32.15V47.03c0-7.16,1.78-8.91,9.08-8.91h3.08c7.3,0,9.08,1.75,9.08,8.91
		v40.43c0,8.91,2.19,12.57,14.67,12.57s14.67-3.66,14.67-12.57V47.03z"
        />
        <path
          class="st0"
          :class="!dark ? '' : 'fill-current text-white'"
          d="M521.51,105.19c0-7.48,1.94-9.39,8.92-7.48c6.16,1.75,13.46,2.54,22.05,2.54c9.56,0,12.48-0.95,12.48-4.61
		c0-2.71-1.62-3.98-10.21-7.32l-15.08-6.21c-11.51-4.62-16.86-10.35-16.86-22.6c0-15.91,9.57-23.4,33.08-23.4
		c6.32,0,12,0.63,17.18,1.91c7.13,1.75,8.92,3.5,8.92,10.51v1.59c0,7.16-1.94,8.92-9.08,7.16c-5.35-1.27-11.18-1.91-17.34-1.91
		c-9.24,0-12,1.27-12,4.3c0,2.39,1.46,3.34,6.65,5.41l16.86,6.52c12.81,4.93,18.64,11.14,18.64,23.87
		c0,16.55-10.21,24.03-34.86,24.03c-7.94,0-14.75-0.63-20.43-2.07c-7.13-1.75-8.92-3.5-8.92-10.51V105.19z"
        />
      </g>
    </svg>
    <!-- <svg
      width="132px"
      height="41px"
      viewBox="0 0 132 41"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Itrinus Logo</title>
      <g
        id="🎯-Hive---Marketing"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Desktop-HD"
          transform="translate(-331.000000, -17.000000)"
          fill-rule="nonzero"
        >
          <g id="Hive_logo_ae" transform="translate(331.247800, 17.000000)">
            <path
              d="M33.665983,6.55131318 C32.581721,5.93599647 31.2401765,5.93599647 30.1559146,6.55131318 L27.6014669,7.99911719 L23.1082117,10.5599205 L23.1082117,9.51931141 L23.1082117,7.81814169 C23.1082117,6.58750828 22.4374395,5.44736261 21.3531775,4.82299713 L18.9733144,3.47472964 L13.6898344,0.46148753 C12.6055724,-0.153829177 11.264028,-0.153829177 10.179766,0.46148753 L2.50723421,4.82299713 C1.42297224,5.43831384 0.7522,6.5784595 0.7522,7.81814169 L0.7522,16.5411609 C0.7522,17.7717943 1.42297224,18.91194 2.50723421,19.5363055 L10.179766,23.8978151 C10.721897,24.2054734 11.3283486,24.3593026 11.9348002,24.3593026 C12.5412518,24.3593026 13.1477034,24.2054734 13.6898344,23.8978151 L14.0481922,23.698742 L16.1064522,22.53145 L16.1064522,30.5486648 C16.1064522,30.5577135 16.1064522,30.5667623 16.1064522,30.5848599 C16.1156409,31.8064445 16.7864131,32.9284926 17.8614864,33.5438093 L24.8448686,37.5071728 L30.1742919,40.5385125 C30.7164229,40.8461708 31.3228745,41 31.9293261,41 C32.5357777,41 33.1422293,40.8461708 33.6843603,40.5385125 L45.9971658,33.5438093 C47.0814278,32.9284926 47.7522,31.7883469 47.7522,30.5486648 L47.7522,16.5411609 C47.7338227,15.3105275 47.0630504,14.1703818 45.9787885,13.5460163 L33.665983,6.55131318 Z M11.9348002,16.9121607 L7.77233685,14.5413816 L7.77233685,9.80887221 L11.9348002,7.43809314 L11.9715548,7.45619069 L16.1064522,9.80887221 L16.1064522,14.5413816 L11.9348002,16.9121607 Z M40.7136858,28.5398367 L31.9109488,33.5438093 L31.8374395,33.5076142 L23.1082117,28.5398367 L23.1082117,18.5318914 L31.9109488,13.5279188 L40.7136858,18.5318914 L40.7136858,28.5398367 Z"
              id="Shape"
              fill="#FF972E"
              class="fill-current"
              :class="!dark ? 'text-primary-500' : 'text-white'"
            ></path>
            <g
              id="Group"
              transform="translate(54.752200, 10.000000)"
              class="fill-current"
              :class="!dark ? 'text-tertiary-900' : 'text-white'"
            >
              <polygon
                id="Path"
                points="16.2090909 0.759756098 21.7727273 0.759756098 21.7727273 27.4365854 16.2090909 27.4365854 16.2090909 16.3817073 5.65454545 16.3817073 5.65454545 27.4365854 0.0545454545 27.4365854 0.0545454545 0.759756098 5.65454545 0.759756098 5.65454545 11.3536585 16.2090909 11.3536585"
              ></polygon>
              <path
                d="M25.8181818,3.08170732 C25.8181818,1.44268293 27.2818182,0.0341463415 29.0272727,0.0341463415 C30.8090909,0.0341463415 32.2727273,1.44268293 32.2727273,3.08170732 C32.2727273,4.72073171 30.8090909,6.09512195 29.0272727,6.09512195 C27.2818182,6.09512195 25.8181818,4.72073171 25.8181818,3.08170732 M26.4272727,8.38292683 L31.6636364,8.38292683 L31.6636364,27.4365854 L26.4272727,27.4365854 L26.4272727,8.38292683 Z"
                id="Shape"
              ></path>
              <polygon
                id="Path"
                points="49.1818182 8.38292683 54.9454545 8.38292683 47.2272727 27.4365854 41.2636364 27.4365854 33.5545455 8.38292683 39.3181818 8.38292683 44.2272727 21.7170732"
              ></polygon>
              <path
                d="M60.4272727,19.9243902 C61.1181818,22.3231707 63.0272727,23.5097561 66.1545455,23.5097561 C68.1454545,23.5097561 69.6818182,22.8609756 70.7,21.6060976 L74.9181818,23.8939024 C72.9272727,26.6 69.9636364,27.9743902 66.0727273,27.9743902 C62.7,27.9743902 60.0272727,27.0182927 58,25.1146341 C55.9727273,23.2109756 54.9545455,20.8036585 54.9545455,17.9097561 C54.9545455,15.05 55.9727273,12.6512195 57.9545455,10.747561 C59.9454545,8.80121951 62.5363636,7.85365854 65.6636364,7.85365854 C68.6272727,7.85365854 71.1,8.8097561 73.0090909,10.747561 C74.9545455,12.6939024 75.9272727,15.0585366 75.9272727,17.9097561 C75.9272727,18.5585366 75.8454545,19.2073171 75.7272727,19.9329268 L60.4272727,19.9329268 L60.4272727,19.9243902 Z M70.7,16.1170732 C70.0909091,13.5219512 68.0636364,12.2670732 65.6636364,12.2670732 C62.8636364,12.2670732 60.9545455,13.6756098 60.3454545,16.1170732 L70.7,16.1170732 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg> -->
  </router-link>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '250px'
    }
  }
}
</script>

<style>
.st0 {
  fill: #004c97;
}
.st1 {
  fill: #ffc845;
}
.st2 {
  fill: url(#SVGID_1_);
}
.st3 {
  fill: url(#SVGID_2_);
}
</style>
