<template>
  <div class="w-full h-full flex">
    <div class="w-full md:w-1/2 h-full flex justify-center items-center">
      <slot></slot>
    </div>
    <div
      class="hidden rightSide md:flex w-1/2 h-full justify-center items-center"
    >
      <logo dark />
    </div>
  </div>
</template>

<script>
import Logo from '@/_components/Logo.vue'
export default {
  components: { Logo }
}
</script>

<style scoped>
.rightSide {
  background: url('~@/assets/img/itrinus.jpg');
  background-position: top;
  background-size: cover;
}
</style>
