<template>
  <splitted-layout class="w-full h-full flex px-2 md:px-0">
    <on-form
      class="max-w-md"
      title="Login"
      subtitle="Inserisci le credenziali di accesso"
    >
      <template v-slot:prepend>
        <div class="w-full h-32 flex items-center justify-center">
          <logo size="200px" />
        </div>
      </template>
      <template class="my-4" v-slot:content>
        <div class="grid grid-row-2 gap-4 align-baseline items-baseline">
          <on-input
            class="w-full text-gray-600"
            placeholder="Email"
            v-model="email"
          ></on-input>
          <on-input
            inputType="password"
            placeholder="Password"
            v-model="password"
          ></on-input>
        </div>
      </template>

      <template v-slot:action>
        <div class="flex flex-col gap-4 items-center">
          <on-button
            :buttonClasses="['text-white', 'bg-primary-500']"
            @click="login"
            type="submit"
          >
            Login
          </on-button>

          <span class="text-sm block my-4 text-right self-end px-2">
            <router-link
              :to="`/forgot`"
              class="text-primary-400 font-bold hover:text-primary-600 "
              >Forgot Password?</router-link
            >
          </span>
          <span class="mt-20 text-gray-500 text-sm">
            Non hai un account?
            <router-link :to="`/signup`" class="text-primary-400 font-bold">
              Registrati
            </router-link>
          </span>
        </div>
      </template>
    </on-form>
  </splitted-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import Logo from '@/_components/Logo'
import OnForm from '@/_components/_ui/OnForm.vue'
import SplittedLayout from '@/_templates/SplittedLayout.vue'
import OnInput from '../../_components/_ui/OnInput.vue'
import OnButton from '@/_components/_ui/OnButton.vue'
export default {
  components: { OnForm, SplittedLayout, OnInput, Logo, OnButton },
  data: () => ({
    email: '',
    password: ''
  }),
  computed: {
    ...mapGetters(
      { authError: 'auth/error' },
      { currentUSerData: 'auth/currentUserData' }
    )
  },
  methods: {
    login() {
      try {
        console.log('Provo il login ')
        this.$store.dispatch('auth/signIn', {
          email: this.email,
          password: this.password
        })
      } catch (error) {
        console.log('Errore login', error)
      }
    }
  }
}
</script>

<style lang="postcss" scoped></style>
