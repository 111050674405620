<template>
  <splitted-layout class="w-full h-full flex px-2 md:px-0 relative">
    <on-form
      class="max-w-md"
      title="Registrazione"
      subtitle="Completa i campi sottostanti per usufruire dei servzi di iTrinus"
    >
      <template v-slot:prepend>
        <div class="w-full h-32 flex items-center justify-center">
          <logo size="200px" />
        </div>
      </template>
      <template class="my-4" v-slot:content>
        <div class="grid grid-row-2 gap-4 align-baseline items-baseline">
          <on-input
            class="w-full text-gray-600"
            placeholder="Nome*"
            v-model="name"
            :classes="fieldStatus($v.name)"
          ></on-input>
          <on-input
            class="w-full text-gray-600"
            placeholder="Cognome*"
            v-model="family_name"
            :classes="fieldStatus($v.family_name)"
          ></on-input>
          <on-input
            class="w-full text-gray-600"
            placeholder="Email*"
            v-model="email"
            :classes="fieldStatus($v.email)"
          ></on-input>
          <on-input
            inputType="password"
            placeholder="Password*"
            v-model="password"
            :classes="fieldStatus($v.password)"
          ></on-input>
          <on-input
            inputType="password"
            placeholder="Ripeti Password*"
            v-model="repeatPassword"
            :classes="fieldStatus($v.repeatPassword)"
          ></on-input>
        </div>
      </template>
      <template v-if="submitErrors || $v.$error" v-slot:errors>
        <span v-if="$v.$invalid">
          Il form contiene degli errori. Correggerli e riprovare
        </span>
        <span v-else class="text-sm px-1 text-center">
          {{ submitErrors }}
        </span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col gap-4 items-center">
          <on-button
            :buttonClasses="['text-white', 'bg-primary-500']"
            @click="signUp"
            type="submit"
          >
            Registrati
          </on-button>

          <span class="mt-20 text-gray-500 text-sm">
            Sei già registrato?
            <router-link :to="`/login`" class="text-primary-400 font-bold">
              Accedi
            </router-link>
          </span>
        </div>
      </template>
      <template v-slot:error>
        <span class="text-red text-sm mt-4">
          {{ authError }}
        </span>
      </template>
    </on-form>

    <on-modal
      v-if="showVerificationPopup"
      @close="showVerificationPopup = false"
    >
      <template v-slot:body>
        <div
          class="flex flex-col w-full lg:w-3/4 lg:mx-auto items-center justify-center"
        >
          <div class="items-center text-center w-full">
            <img src="@/assets/img/confirmation.svg" class=" m-auto" />
          </div>
          <div class="text-center mt-2">
            <div class="text-black font-bold text-2xl">
              Conferma account
            </div>
          </div>
          <div class="text-gray-800 text-center text-sm mt-3">
            Abbiamo inviato una email all'indirizzo
            <span class="text-primary-500">{{ email }}</span> con il codice di
            conferma necessario ad attivare l'account. Inserisci il codice ed
            inizia ad utilizzare il servizio Se.Va.Ra
          </div>
          <div class="mt-5 text-center w-full">
            <on-input
              label="Codice di conferma"
              :inline="false"
              classes="mt-2 w-full max-w-xs"
              placeholder="codice"
              v-model="verificationCode"
            >
              <!-- <template v-slot:error>
                <span v-if="authError" class="text-sm text-red-500">{{
                  authError
                }}</span>
              </template> -->
            </on-input>
          </div>

          <button
            class="px-8 py-3  text-white bg-primary-500 rounded-md text-sm
            cursor-pointer hover:shadow-md text-center mt-3"
            type="button"
            @click="confirmSignUp"
            animate-spin
          >
            Invia
          </button>
          <p class="text-gray-800 text-center text-sm mt-14">
            Non hai ricevuto la mail?
            <span
              class="text-primary-500 font-medium"
              @click="resendConfirmationCode"
              >Reinvia la mail di verifica</span
            >
          </p>
        </div>
      </template>
    </on-modal>
  </splitted-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import OnForm from '@/_components/_ui/OnForm.vue'
import SplittedLayout from '@/_templates/SplittedLayout.vue'
import OnInput from '@/_components/_ui/OnInput.vue'
import OnModal from '@/_components/_ui/OnModal.vue'
import { validationMixin } from 'vuelidate'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import Logo from '@/_components/Logo.vue'
import OnButton from '@/_components/_ui/OnButton.vue'
export default {
  name: 'SignUp',
  components: {
    OnForm,
    SplittedLayout,
    OnInput,
    OnModal,
    Logo,
    OnButton
  },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      showVerificationPopup: false,
      verificationCode: '',
      email: '',
      password: '',
      repeatPassword: '',
      name: '',
      family_name: '',
      submitErrors: null
    }
  },
  validations: {
    name: { required, minLength: minLength(4) },
    family_name: { required, minLength: minLength(4) },
    email: { required, minLength: minLength(4), email },
    password: { required, minLength: minLength(8) },
    repeatPassword: {
      required,
      minLength: minLength(4),
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    ...mapGetters({ authError: 'auth/error' })
  },
  methods: {
    fieldStatus(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    async signUp() {
      try {
        this.showVerificationPopup = false
        this.$v.$touch()
        if (!this.$v.$invalid) {
          let _signUpState = await this.$store.dispatch('auth/signUp', {
            email: this.email,
            password: this.password,
            name: this.name,
            family_name: this.family_name
          })
          console.log('Registrazione ', _signUpState)
          this.showVerificationPopup = true
        }
      } catch (error) {
        console.log('Errore di Registrazione ', error)
      }
    },
    async resendConfirmationCode() {
      try {
        this.$store.dispatch('auth/resendSignUp', {
          email: this.email
        })
        console.log('Codice inviato con successo')
        this.showVerificationPopup = true
      } catch (error) {
        console.log('Errore di invio Codice', error)
      }
    },
    async confirmSignUp() {
      try {
        console.log('Provo la conferma ')
        this.$store.dispatch('auth/confirmSignUp', {
          email: this.email,
          code: this.verificationCode
        })
      } catch (error) {
        this.verificationError = true
        console.log('Errore login', error)
      }
    }
  }
}
</script>

<style>
.modal-container {
  max-width: 768px;
}
</style>
