<template>
  <splitted-layout class="w-full h-full flex px-2 md:px-0">
    <on-form
      class="max-w-md"
      title="Recupera Password"
      subtitle="Aggiorna i tuoi dati di accesso"
    >
      <template v-slot:prepend>
        <div class="w-full h-32 flex items-center justify-center">
          <logo size="200px" />
        </div>
      </template>
      <template class="my-4" v-slot:content>
        <div class="grid grid-row-2 gap-4 align-baseline items-baseline">
          <on-input
            inputType="password"
            placeholder="Password*"
            v-model="password"
            :classes="fieldStatus($v.password)"
          ></on-input>
          <on-input
            inputType="password"
            placeholder="Ripeti Password*"
            v-model="repeatPassword"
            :classes="fieldStatus($v.repeatPassword)"
          ></on-input>
          <on-input
            inputType="text"
            placeholder="Codice di verifica*"
            v-model="verificationCode"
            :classes="fieldStatus($v.verificationCode)"
          ></on-input>
        </div>
      </template>

      <template v-slot:action>
        <div class="flex flex-col gap-4 items-center">
          <on-button
            :buttonClasses="['text-white', 'bg-primary-500']"
            type="submit"
            @click="resetPassword"
          >
            Procedi
          </on-button>

          <span class="mt-20 text-gray-500 text-sm">
            Sei già registrato?
            <router-link :to="`/login`" class="text-primary-400 font-bold">
              Accedi
            </router-link>
          </span>
        </div>
      </template>
    </on-form>
  </splitted-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/_components/Logo'
import OnForm from '@/_components/_ui/OnForm.vue'
import SplittedLayout from '@/_templates/SplittedLayout.vue'
import OnInput from '@/_components/_ui/OnInput.vue'
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import OnButton from '@/_components/_ui/OnButton.vue'
export default {
  name: 'ResetPassword',
  components: { OnForm, SplittedLayout, OnInput, Logo, OnButton },
  mixins: [validationMixin],
  data: () => ({
    verificationCode: '',
    password: '',
    repeatPassword: ''
  }),
  validations: {
    verificationCode: { required },
    password: { required, minLength: minLength(8) },
    repeatPassword: {
      required,
      minLength: minLength(4),
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    ...mapGetters({ email: 'auth/currentEmail' })
  },
  methods: {
    fieldStatus(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    resetPassword() {
      try {
        console.log('Provo il reset della password ')
        this.$store.dispatch('auth/forgotPasswordSubmit', {
          email: this.email,
          code: this.verificationCode,
          newPassword: this.password
        })
      } catch (error) {
        console.log('Errore reset password', error)
      }
    }
  }
}
</script>

<style lang="postcss" scoped></style>
